<template>
  <div class="mt-1">
    <b-card>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-row class="mb-1">
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"> </b-col>
          <b-col cols="12" md="7">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
              <b-button variant="primary" @click="addObjectCode()">
                <span class="text-nowrap">Add Object Code</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-table :outlined="true" :fields="fields" responsive="sm" class="table table-sm" :items="filteredObjectCodes">
          <template #cell(code)="data">
            <div>{{ data.value }}</div>
          </template>

          <template #cell(description)="data">
            <div>{{ data.value }}</div>
          </template>

          <template #cell(actions)="data">
            <div>
              <b-button variant="primary" class="mr-25 p-50" @click="editObjectCode(data.item.id)">
                <i class="fa-solid fa-pen-to-square mr-25"></i>
                <span> Edit</span>
              </b-button>

              <b-button variant="primary" @click="deleteObjectCode(data.item.id)" class="p-50">
                <i class="fa-solid fa-trash mr-25"></i>
                <span> Delete</span>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const OBJECT_CODE_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(OBJECT_CODE_APP_STORE_MODULE_NAME)) store.registerModule(OBJECT_CODE_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OBJECT_CODE_APP_STORE_MODULE_NAME)) store.unregisterModule(OBJECT_CODE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      objectCodes: [],
      filter: null,
      loading: true,
      fields: [
        { key: 'code', sortable: true, class: 'text-left', thStyle: 'width:20%' },
        { key: 'description', sortable: true, class: 'text-left', thStyle: 'width:60%' },
        { key: 'actions', sortable: false, class: 'text-center', thStyle: 'width:20%' },
      ],
      tempName: null,
      showAllAdditionals: false,
    };
  },

  watch: {},

  methods: {
    getObjectCodes() {
      this.loading = true;
      store
        .dispatch('settings/getAllObjectCodes')
        .then((res) => {
          this.objectCodes = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$router.push({ name: 'settings-dashboard' });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    editObjectCode(id) {
      this.$router.push({ name: 'settings-objectcode-edit', params: { id: id } });
    },

    deleteObjectCode(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this object code?', {
          size: 'sm',
          title: 'Confirm Deletion',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const objectCode = this.objectCodes.find((met) => met.id === id);

            if (objectCode) {
              store
                .dispatch('settings/deleteObjectCode', objectCode)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ ObjectCode Delete Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.getObjectCodes();
                })
                .catch((error) => {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          }
        });
    },

    activeObjectCode(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to activate this objectCode?', {
          size: 'sm',
          title: 'Confirm Activation',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const objectCode = this.objectCodes.find((met) => met.id === id);

            if (objectCode) {
              store
                .dispatch('settings/activeObjectCode', objectCode)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ ObjectCode Activate Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.getObjectCodes();
                })
                .catch((error) => {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          }
        });
    },

    addObjectCode() {
      this.$router.push({ name: 'settings-objectcode-add' });
    },
  },

  created() {
    this.getObjectCodes();
  },

  computed: {
    filteredObjectCodes() {
      if (!this.filter) {
        return this.objectCodes;
      } else {
        this.objectCodes = this.objectCodes.filter((met) => met.id !== undefined);

        this.objectCodes.forEach((met) => {
          met.editable = false;
        });

        const searchTerm = this.filter.toLowerCase();
        return this.objectCodes.filter((met) => met.code.toLowerCase().includes(searchTerm));
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
