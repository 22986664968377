import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Departmentss
    getAllDepartments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getAllDepartments', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateDepartment(ctx, depData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateDepartment/${depData.id}`, depData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteDepartment(ctx, depData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`deleteDepartment/${depData.id}`, depData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveDepartment(ctx, depData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveDepartment', depData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Labssss
    getAllLabs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getAllLabs', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateLab(ctx, depData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateLab/${depData.id}`, depData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteLab(ctx, depData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`deleteLab/${depData.id}`, depData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveLab(ctx, depData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveLab', depData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Materialsss

    getAllMaterials(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getAllMaterials', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getMaterialById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getMaterialById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateMaterial(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateMaterial/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteMaterial(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`deleteMaterial/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    activeMaterial(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`activeMaterial/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMaterial(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveMaterial', metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //object-code

    getAllObjectCodes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getAllObjectCodes', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getObjectCodeById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getObjectCodeById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateObjectCode(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateObjectCode/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteObjectCode(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`deleteObjectCode/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveObjectCode(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveObjectCode', metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Settings

    getSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getSettings', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateSettings(ctx, setData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateSettings`, setData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTerms(ctx, setData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateTerms`, setData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateNote(ctx, setData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateNote`, setData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Surveys Questions

    getSurvey(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getSurvey', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    saveSurvey(ctx, depData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveSurvey', depData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
